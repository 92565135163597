import Box from '@amzn/awsui-components-react/polaris/box';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import Container from '@amzn/awsui-components-react/polaris/container';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Header from '@amzn/awsui-components-react/polaris/header';
import FormInput from './FormInput';
// import OpportunityTaskPanel from "./SubmitButton";
import ClearButton from "./ClearButton";
// import DetailsTable from '../table/Table';
// import FormPage from "../form/FormPage";
// import {PetsApiFactory} from "../../pets-api/generated-src";
// import { Pet } from '../../pets-api/generated-src';
// import PetsTable from "../table/Table";
import TerritoryDropdown from "./TerritoryDropdown";
import HomepageInputGrid from "./HomepageInputGrid";
import * as React from "react";
import HomepageInput from "./HomepageInput";
// import OpportunityTaskPanel from "./SubmitButton";
import SubmitButton from "./SubmitButton";
import Button from "@amzn/awsui-components-react/polaris/button";

// const PetsApi = PetsApiFactory();


// The content in the main content area of the App layout
export default function HomepageContent() {
  return (
    <>
      <Box padding={{ top: 'xxl', horizontal: 's', bottom: 'l' }}>
        <Grid
          gridDefinition={[
            { colspan: { xl: 10, l: 9, s: 10, xxs: 10 }, offset: { l: 2, xxs: 1 } }
          ]}
        >
          <SpaceBetween size="l">
            <div>
              <Header variant="h1" headingTagOverride="h2">
                Autorip eligibility check
              </Header>
              <Container>
                <HomepageInput/>
                {/*<DetailsTable/>*/}
              </Container>
            </div>
          </SpaceBetween>
        </Grid>
      </Box>
    </>
  );
}
