import { Route, Switch } from 'react-router-dom';
import Homepage from './home';
import CommonHeader from './CommonHeader';
// import FormPage from './form/FormPage';

export default function App() {
  return (
    <div>
      <CommonHeader />
      <Switch>
        {/*<Route path="/form/:mode/:id" component={FormPage} />*/}
        <Route exact path="/" component={Homepage} />
      </Switch>
    </div>
  );
}
